import zxcvbn from '@generalassembly/zxcvbn'
import $ from 'jquery'

/* eslint-disable no-undef */
const FEEDBACK_MESSAGES = {
  use_a_few_words: I18n.t('account.form.password_feedback.use_a_few_words'),
  no_need_for_mixed_chars: I18n.t('account.form.password_feedback.no_need_for_mixed_chars'),
  uncommon_words_are_better: I18n.t('account.form.password_feedback.uncommon_words_are_better'),
  straight_rows_of_keys_are_easy: I18n.t('account.form.password_feedback.straight_rows_of_keys_are_easy'),
  short_keyboard_patterns_are_easy: I18n.t('account.form.password_feedback.short_keyboard_patterns_are_easy'),
  use_longer_keyboard_patterns: I18n.t('account.form.password_feedback.use_longer_keyboard_patterns'),
  repeated_chars_are_easy: I18n.t('account.form.password_feedback.repeated_chars_are_easy'),
  repeated_patterns_are_easy: I18n.t('account.form.password_feedback.repeated_patterns_are_easy'),
  avoid_repeated_chars: I18n.t('account.form.password_feedback.avoid_repeated_chars'),
  sequences_are_easy: I18n.t('account.form.password_feedback.sequences_are_easy'),
  avoid_sequences: I18n.t('account.form.password_feedback.avoid_sequences'),
  recent_years_are_easy: I18n.t('account.form.password_feedback.recent_years_are_easy'),
  avoid_recent_years: I18n.t('account.form.password_feedback.avoid_recent_years'),
  avoid_associated_years: I18n.t('account.form.password_feedback.avoid_associated_years'),
  dates_are_easy: I18n.t('account.form.password_feedback.dates_are_easy'),
  avoid_associated_dates_and_years: I18n.t('account.form.password_feedback.avoid_associated_dates_and_years'),
  top10_common_password: I18n.t('account.form.password_feedback.top10_common_password'),
  top100_common_password: I18n.t('account.form.password_feedback.top100_common_password'),
  very_common_password: I18n.t('account.form.password_feedback.very_common_password'),
  similar_to_common_password: I18n.t('account.form.password_feedback.similar_to_common_password'),
  a_word_is_easy: I18n.t('account.form.password_feedback.a_word_is_easy'),
  names_are_easy: I18n.t('account.form.password_feedback.names_are_easy'),
  common_names_are_easy: I18n.t('account.form.password_feedback.common_names_are_easy'),
  capitalization_doesnt_help: I18n.t('account.form.password_feedback.capitalization_doesnt_help'),
  all_uppercase_doesnt_help: I18n.t('account.form.password_feedback.all_uppercase_doesnt_help'),
  reverse_doesnt_help: I18n.t('account.form.password_feedback.reverse_doesnt_help'),
  substitution_doesnt_help: I18n.t('account.form.password_feedback.substitution_doesnt_help'),
  user_dictionary: I18n.t('account.form.password_feedback.user_dictionary'),
}
/* eslint-enable no-undef */
class Password {
  constructor(passwordEl) {
    this.clearState()

    this.passwordEl = passwordEl
    this.passwordName = passwordEl.attr('data-password-name')
    this.scoreThreshold = passwordEl.attr('data-min-complexity')

    this.meterEl = $(
      `.password-meter[data-for-password="${this.passwordName}"]`,
    )
    this.warningEl = $(
      `.password-warning[data-for-password="${this.passwordName}"]`,
    )
    this.suggestionsEl = this.warningEl.find('.password-suggestions')
    this.confirmationEl = $(
      `.password-confirmation[data-for-password="${this.passwordName}"]`,
    )
    this.submitEl = $(`.submit[data-for-password="${this.passwordName}"]`)
    this.suggestionTemplate = this.suggestionsEl.find('.password-suggestion')
    this.suggestionTemplate.remove()

    this.showWarning = false

    passwordEl.on('keyup', () => this.onKeyup())
    passwordEl.on('blur', () => {
      this.showWarning = true
      this.setState()
      this.render()
    })
  }

  onKeyup() {
    this.setState()
    this.render()
  }

  clearState() {
    this.warning = ''
    this.suggestions = []
    this.score = ''
  }

  setState() {
    this.clearState()

    if (this.blank()) {
      this.showWarning = false
      return
    }

    // eslint-disable-next-line no-undef
    I18n.locale = I18n.locale
    const result = zxcvbn(this.passwordEl.val(), {
      feedback_messages: FEEDBACK_MESSAGES,
    })

    this.warning = result.feedback.warning
    this.suggestions = result.feedback.suggestions
    this.score = result.score
    this.guesses_log10 = result.guesses_log10
  }

  valid() {
    return this.score >= this.scoreThreshold
  }

  blank() {
    return !this.passwordEl.val()
  }

  clear() {
    this.warningEl.removeClass('password-warning--visible')
    this.warningEl.find('.password-warning-message').empty()
    this.warningEl.find('.password-suggestions').empty()
    this.meterEl
      .find('.password-meter-bar')
      .toArray()
      .forEach(bar => this.deactivateBar($(bar)))
    this.confirmationEl.removeAttr('disabled')
    this.submitEl.removeAttr('disabled')
    this.passwordEl.removeClass('password--invalid')
    this.meterEl.removeClass('password-meter--invalid')
  }

  render() {
    this.clear()

    if (this.blank()) {
      return
    }

    this.renderMeter()

    if (this.showWarning) {
      this.renderWarning()
      this.renderInputErrors()
    }
  }

  renderMeter() {
    const numberOfBars = this.numberOfBars()

    this.meterEl
      .find('.password-meter-bar')
      .toArray()
      .forEach((bar) => {
        if (numberOfBars >= $(bar).data('score')) {
          this.activateBar($(bar))
        }
      })
  }

  renderWarning() {
    if (!this.valid()) {
      this.warningEl.addClass('password-warning--visible')
    }

    if (this.warning) {
      this.warningEl
        .find('.password-warning-message')
        .append(`${this.warning}.`)
    }

    this.suggestions.forEach((suggestion) => {
      this.suggestionTemplate
        .clone()
        .html(suggestion)
        .appendTo(this.suggestionsEl)
    })
  }

  renderInputErrors() {
    if (this.valid()) {
      return
    }

    this.confirmationEl.attr('disabled', 'disabled')
    this.submitEl.attr('disabled', 'disabled')
    this.passwordEl.addClass('password--invalid')
    this.meterEl.addClass('password-meter--invalid')
  }

  activateBar(bar) {
    if (this.valid()) {
      bar.addClass('password-meter-bar--valid')
    } else {
      bar.addClass('password-meter-bar--invalid')
    }
  }

  deactivateBar = (bar) => {
    bar.removeClass('password-meter-bar--valid')
    bar.removeClass('password-meter-bar--invalid')
  }

  warningMessage() {
    if (this.warning) {
      return `${this.warning}. ${this.defaultWarning()}`
    }
    return this.defaultWarning()
  }

  numberOfBars() {
    return this.score
  }
}

// eslint-disable-next-line func-names
$('.with-password-complexity').each(function () {
  // eslint-disable-next-line no-new
  new Password($(this))
})
